<template>
    <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="flex flex-column align-items-center justify-content-center">
            <div style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #0f97c7 10%, rgba(33, 150, 243, 0) 30%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius: 53px">
                    <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">SOL - Central de Chamados</h1>
                    <div class="text-600 mb-5">Acesse os Formulários de Solicitação.</div>
                    <router-link to="/sol/chamado/curadoria" class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <span class="flex justify-content-center align-items-center bg-cyan-400 border-round" style="height: 1.5rem; width: 1.5rem">
                        </span>
                        <span class="ml-4 flex flex-column">
                            <span class="text-900 lg:text-xl font-medium mb-0 block">Solicitação de Curadoria</span>
                            <span class="text-600 lg:text-xl">Faça sua solicitação para avaliação e curadoria de conteúdo.</span>
                        </span>
                    </router-link>
                    <router-link to="/sol/chamado/consultoria" class="w-full flex align-items-center py-5 border-300 border-bottom-1">
                        <span class="flex justify-content-center align-items-center bg-orange-400 border-round" style="height: 1.5rem; width: 1.5rem">
                        </span>
                        <span class="ml-4 flex flex-column">
                            <span class="text-900 lg:text-xl font-medium mb-0">Solicitação de Consultorias Expreess</span>
                            <span class="text-600 lg:text-xl">Obtenha consultorias rápidas e eficazes para suas necessidades.</span>
                        </span>
                    </router-link>
                    <router-link to="/sol/chamado/produtoReal" class="w-full flex align-items-center mb-5 py-5 border-300 border-bottom-1">
                        <span class="flex justify-content-center align-items-center bg-indigo-400 border-round" style="height: 1.5rem; width: 1.5rem">
                        </span>
                        <span class="ml-4 flex flex-column">
                            <span class="text-900 lg:text-xl font-medium mb-0">Solicitação de Produto Real</span>
                            <span class="text-600 lg:text-xl">Peça o produto que você precisa, e nós o tornaremos realidade.</span>
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>